import React, { Component } from 'react'
import styled from '@emotion/styled'
import './Home.css'
import { connect } from 'react-redux'
import cognitoUtils from '../lib/cognitoUtils'
import axios from 'axios'
import fileDownload from 'js-file-download'
//import appConfig from '../config/app-config.json'

import config from '../config/query-config.json'

import { Button } from '@rmwc/button'
import { TextField } from '@rmwc/textfield'
import { Snackbar, SnackbarAction } from '@rmwc/snackbar'

import { LinearProgress } from '@rmwc/linear-progress'
import { ThemeProvider } from '@rmwc/theme'


import HomeContainer from '../components/HomeContainer'

import QueryBuilder from '../components/QueryBuilder'

const mapStateToProps = state => {
  return { session: state.session }
}

const StyledTextField = styled(TextField)`
  width: calc(100% - 200px);
`
//var fileName = 0;
// let limit = 20;
// let offset = 0;
// let key = "fileName";
// let search = "";

class Home extends Component {
  constructor (props) {
    super(props)
    this.state = { 
      apiStatus: 'Not called', 
      loading: false,
      limit: 20,
      offset: 0,
      key: 'fileName',
      search: '',
      apiResponse: {
        results: []
      },
      tableColumns: config.gridHeadingsStory,
      snackOpen: false,
      snackMessage: 'Downloading files',
      activeTab: 0,
      activeType: 'Story'
    }
  }

  componentDidMount () {
    const qs = this.buildQueryString({
      fileType: 'Story',
      masthead: '',
      state: '',
      edition: '',
      section: '',
      modifier: '',
      author: ''
    })
    console.log(`this.props.session.isLoggedIn, ${this.props.session.isLoggedIn}`)
    if(this.props.session.isLoggedIn){
      this.onCallApi(qs)
    }else{
      window.location.href=cognitoUtils.getCognitoSignInUri()
    }
    console.log(process.env)
    console.log(process.env.REACT_APP_CALLBACK, process.env.REACT_APP_SIGNOUT)
  }

  onCallApi = (queryString) => {
    let apiStatus, apiResponse, filtered
    console.log(`${process.env.REACT_APP_API_URL}search${queryString}`)
    if (this.props.session.isLoggedIn) {
      const options = {
        url: `${process.env.REACT_APP_API_URL}search${queryString}`,
        headers: {
          "Authorization": this.props.session.credentials.accessToken
        }
      }
      this.setState({ apiStatus: 'Loading...', loading: true })
      axios.request(options)
      .then((res) => {
        if(res.status !== 200){
          apiStatus = 'Error response received'
          apiResponse = res.data
        }else{
          apiStatus = 'Successful response received.'
          apiResponse = res.data
          filtered = apiResponse.results.filter(item => item.fileName !== 'Stories/' && item.fileName !== 'Pages/');
        }
        if(queryString.indexOf('Story') === -1){
          this.setState({tableColumns: config.gridHeadingsPage})
        }else{
          this.setState({tableColumns: config.gridHeadingsStory})
        }
        this.setState({ apiStatus, apiResponse: {results: filtered}, loading: false })
      })
      .catch(err => {
        // Update frontend with message
        apiStatus = 'Unable to reach API'
        this.setState({ apiStatus, apiResponse, loading: false })
      })
    }
  }

  onSignOut = (e) => {
    e.preventDefault()
    cognitoUtils.signOutCognitoSession()
  }

  onSignIn = (e) => {
    e.preventDefault()
    console.log(cognitoUtils.getCognitoSignInUri())
    window.location.href=cognitoUtils.getCognitoSignInUri()
  }

  onSearch = (obj) => {
    console.log(obj)
    this.onCallApi(this.buildQueryString(obj))
  }

  onDownloadLib = (filename) => {
    this.setState({ loading: true })

    const params = `?keys=${filename}&user=${this.props.session.user.email.split('@')[0]}&bucket=methode-offline-libs-${process.env.REACT_APP_ENV}&exp=600`
      const options = {
        url: `${process.env.REACT_APP_API_URL}download${params}`,
        headers: {
          Authorization: `${this.props.session.credentials.accessToken}`
        }
      }

      axios.request(options)
      .then((res) => {
        console.log(res)
        this.setState({ loading: false })
        if(res.status !== 200){
          console.log('error getting download link')
          this.setState({ snackOpen: true, snackMessage: 'Error getting download link' })
        }else{
          console.log(`res.data.url: ${res.data.url}`)
          this.setState({ snackOpen: true, snackMessage: 'Success getting download link' })
          window.open(res.data.url, "_blank")
        }
      })
      .catch(err => {
        this.setState({ loading: false, snackOpen: true, snackMessage: 'Error getting download link' })
        // Update frontend with message
        console.log('error getting download link')
      })
  }

  cleanQueryObject = (obj) => {
    const fields = obj.fileType === 'Story' ? config.queryFieldsStory : config.queryFieldsPage
    //console.log(fields)
    let clean = {fileType: obj.fileType};
    fields.forEach(item => {
      if(obj[item]){
        if(item === 'issued'){
          if(!obj.issuedDisabled){
            let arr = obj[item].split('/');
            clean[item] = `${arr[1]}/${arr[0]}/${arr[2]}`
          }
        }else{
          clean[item] = obj[item]
        }
        
      } 
    })
    console.log(clean)
    return clean;
  }

  buildQueryString = (obj) => {
    let clean = this.cleanQueryObject(obj)
    var qs = '?'
    Object.entries(clean).forEach(([key, value], index) => {
      if((value !== '') && (key === 'state' && value !== '' || value !== 'All')){
        qs += `${key}=${value}`;
      } 
      if(index < Object.entries(clean).length -1 && value !== ''){
        qs += '&'
      }
    });
    return qs;
  }

  onSearchChange = (e) => {
    // const searchText = e.target.value
    // if (searchText === '') {
    //   this.props.handleResetPage()
    // }
    this.setState({ search: e.target.value })
  }

  downloadMultipleFiles = (keys) => {
    const params = `?keys=${keys}&user=${this.props.session.user.email.split('@')[0]}`
    console.log(params)
    const options = {
        url: `${process.env.REACT_APP_API_URL}downloadzip${params}`,
        headers: {
          "Authorization": `${this.props.session.credentials.accessToken}`
        }
      }

      axios.request(options)
      .then((res) => {
        console.log(res)
        this.setState({ loading: false })
        if(res.status !== 200){
          console.log('error getting download link')
          this.setState({ snackOpen: true, snackMessage: 'Error downloading files' })
        }else{
          axios.get(res.data.url, {
            responseType: 'blob',
          })
          .then((final) => {
            this.setState({ snackOpen: true, snackMessage: 'Success downloading files' })
            fileDownload(final.data, res.data.key)
          })
        }
      })
      .catch(err => {
        this.setState({ loading: false, snackOpen: true, snackMessage: 'Error downloading files' })
        // Update frontend with message
        console.log('error getting download link')
      })
  }

  downloadSingleFile = (filename) => {
    this.setState({ loading: true })
    const params = `?keys=${filename}&user=${this.props.session.user.email.split('@')[0]}`
      const options = {
        url: `${process.env.REACT_APP_API_URL}download${params}`,
        headers: {
          Authorization: `${this.props.session.credentials.accessToken}`
        }
      }

      axios.request(options)
      .then((res) => {
        console.log(res)
        this.setState({ loading: false })
        if(res.status !== 200){
          console.log('error getting download link')
          this.setState({ snackOpen: true, snackMessage: 'Error downloading files' })
        }else{
          axios.get(res.data.url, {
            responseType: 'blob',
          })
          .then((res) => {
            this.setState({ snackOpen: true, snackMessage: 'Success downloading files' })
            fileDownload(res.data, filename)
          })
        }
      })
      .catch(err => {
        this.setState({ loading: false, snackOpen: true, snackMessage: 'Error downloading files' })
        // Update frontend with message
        console.log('error getting download link')
      })
  }

  onGetDownloadLink = (keys) => {
    console.log('onGetDownloadLink', keys)
    this.setState({snackMessage: 'Downloading files'})
    this.setState({snackOpen: true})
    if(keys.indexOf(',') === -1){
      this.downloadSingleFile(keys)
    }else{
      this.downloadMultipleFiles(keys)
    }
    
  }

  onChangeTab = (e) => {
    console.log('onChangeTab', e)
    this.setState({activeTab: e});
  }

  onClearResults = () => {
    console.log('onClearResults')
    this.setState({apiResponse: {results: []}});
  }

  // downloadFile = (url, fileName) => {
  //   fetch(url, {
  //     method: 'GET',
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       'Content-Type': 'application/xml'
  //     },
  //   })
  //   .then((response) => response.blob())
  //   .then((blob) => {
  //     // Create blob link to download
  //     const url = window.URL.createObjectURL(
  //       new Blob([blob]),
  //     );
  //     console.log(url)
  //     // const link = document.createElement('a');
  //     // link.href = url;
  //     // link.setAttribute(
  //     //   'download',
  //     //   fileName,
  //     // );

  //     // // Append to html link element page
  //     // document.body.appendChild(link);

  //     // // Start download
  //     // link.click();

  //     // // Clean up and remove the link
  //     // link.parentNode.removeChild(link);
  //   });
  // }

  render () {
    return (
      <div className="Home">
        <ThemeProvider
          options={{
            primary: '#0d47a1',
            secondary: '#002171'
          }}
        >
          
          <div className="Home">
          { this.state.loading ? <LinearProgress /> : null}
         
                <div className="Home-container">
                <Snackbar
                    open={this.state.snackOpen}
                    onClose={evt => this.setState({snackOpen: false})}
                    message={this.state.snackMessage}
                    dismissesOnAction
                    action={
                      <SnackbarAction
                        label="Dismiss"
                        onClick={() => console.log('Click Me')}
                      />
                    }
                  />
                  <QueryBuilder onSearchDynamo={this.onSearch} onClearResults={this.onClearResults} onDownloadLib={this.onDownloadLib} isLoggedIn={this.props.session.isLoggedIn} handleChangeTab={this.onChangeTab}/>
                  <HomeContainer activeTab={this.state.activeTab} items={this.state.apiResponse.results} columns={this.state.tableColumns} count={this.state.apiResponse.count} total={this.state.apiResponse.total} onGetDownloadLink={this.onGetDownloadLink} onGetDownloadLink2={this.onGetDownloadLink2} />  
                </div>
              
          
          </div>  
        </ThemeProvider>
      </div>
    )
  }
}

export default connect(mapStateToProps)(Home)

/*
// test user pool with personal okta
//"userPool": "ap-southeast-2_sDSV71MJi",
 // domain
 //"userPoolBaseUri": "http://okta-test.auth.ap-southeast-2.amazoncognito.com",
// client id
//"clientId": "758m55eimrn6644hj91fpd6ajm",

//dev user pool with nca okta
// "userPool": "ap-southeast-2_nG81WpmyV",
// "userPoolBaseUri": "https://dev-edportal.auth.ap-southeast-2.amazoncognito.com",
// client id
//"clientId": "660pmovh2rh1eeea6ms0f1g840",
<header className="Home-header">
            { this.props.session.isLoggedIn ? (
              <div className="Home-header-container">
                <div className="Home-header-top">
                  <Button 
                    onClick={this.onSignOut}>Sign out
                  </Button>  
                </div>               
                
              </div>
            ) : (
              <div className="Home-header-container">
                <div className="Home-header-top">
                  <Button 
                    raised
                    onClick={this.onSignIn}>Sign in
                  </Button>
                </div>
              </div>
            )}
          </header>
{ this.props.session.isLoggedIn ? (
            <div>
              <p>{this.props.session.user.email}</p>
              <p>{this.state.apiStatus}</p>
              { this.state.apiResponse ? (
                <HomeContainer items={this.state.apiResponse.results} count={this.state.apiResponse.count} total={this.state.apiResponse.total} onGetDownloadLink={this.onGetDownloadLink} />
                ) : (
                <div>no results yet</div>
                )}
              
            </div>
            
            ) : (
            <div />
            )}
            */
