import React, { Component } from 'react'
import styled from '@emotion/styled'

import GridListMUI from './GridListMaterialUI'



class HomeContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      items: null,
      loaded: false,
      sortColumn: 1,
      sortDirection: -1
    }
  }

  componentDidMount () {
    console.log("HomeContainer did mount")
  }

  static getDerivedStateFromProps (props, state) {
    if (props.items !== state.items) {
      //console.log(props.items)
      return {
        items: props.items,
        columns: props.columns,
        loaded: true,
        sortColumn: 1,
        sortDirection: -1
      }
    }
    return null
  }

  onGetDownloadLink = (data) => {
    this.props.onGetDownloadLink(data)
  }

  render () {

    return (
      <div className="Home-list">
          { this.state.items ? (
            <div className="Grid-container">
            { this.props.activeTab === 0 ? <GridListMUI items={this.props.items} columns={this.props.columns} handleDownload={this.onGetDownloadLink}/> : null }
            </div>
            ) : (
            <div>
              <p>waiting for results...</p>
            </div>
            )}
          
      </div>
    )
  }
}

export default HomeContainer
//export default connect(mapStateToProps)(HomeList)
//return <Author key={index} data={item} bgColor={color} onDeleteAuthor={this.onDeleteAuthor} onEditAuthor={this.onViewAuthor} filterMode={this.state.filterMode} />
//return <div key={index}>{item.fileName}</div>

//<HomeListItem key={index} data={item} bgColor={color} onGetDownloadLink={this.onGetDownloadLink} />

/*
<Headings>
                <Column>File Name</Column>
                <Column>File Type</Column>
                <Column>Creator</Column>
                <Column>Created</Column>
              </Headings>
              */


